import React from 'react'
import { Link } from 'react-router-dom'

function Cart() {
  return (
    <>

<div class="hero-container-fluid">
      <div class="hero-row">
        <div class="hero-md-12">
          <h3 class="banntext">Cart</h3>
        </div>
      </div>
    </div>

    <div class="cartban">
      <div class="hero-cart-page">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-lg-8">
              <div class="hero-cart-page-inner">
                <div class="table-responsive">
                  <table
                    class="table table-bordered"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody class="align-middle">
                      <tr>
                        <td>
                          <div class="img">
                            <a href="#"
                              ><img src="img/bookstand.jpg" alt="Image"
                            /></a>
                          </div>
                        </td>
                        <td>$99</td>
                        <td>
                          <div class="qty">
                            <button class="btn-minus">
                              <i class="fa fa-minus"></i>
                            </button>
                            <input type="text" value="1" />
                            <button class="btn-plus">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                        <td>$99</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="img">
                            <a href="#"
                              ><img src="img/penstand2.jpg" alt="Image"
                            /></a>
                          </div>
                        </td>
                        <td>$99</td>
                        <td>
                          <div class="qty">
                            <button class="btn-minus">
                              <i class="fa fa-minus"></i>
                            </button>
                            <input type="text" value="1" />
                            <button class="btn-plus">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                        <td>$99</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="img">
                            <a href="#"
                              ><img src="img/bookstand5.jpg" alt="Image"
                            /></a>
                          </div>
                        </td>
                        <td>$99</td>
                        <td>
                          <div class="qty">
                            <button class="btn-minus">
                              <i class="fa fa-minus"></i>
                            </button>
                            <input type="text" value="1" />
                            <button class="btn-plus">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                        <td>$99</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="hero-lg-4">
              <div class="hero-cart-page-inner">
                <div class="hero-row">
                  <div class="hero-md-12">
                    <div class="cart-summary">
                      <div class="cart-content">
                        <h1>Cart Summary</h1>
                        <p>Sub Total<span>$99</span></p>
                        <p>Shipping Cost<span>$1</span></p>
                        <h2>Grand Total<span>$100</span></h2>
                      </div>
                      <div class="cart-btn">
                        <Link to="/checkout">Checkout</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="smith-container">
      <div class="smith-chat-frame">
        <div id="smith-chat-container">
          <div class="smith-chat">
            <div class="smith-chat-header">
              <div class="smith-header-profile">
                <div class="smith-header-profile-name">Maverick Market</div>
              </div>
            </div>
            <div class="smith-chat-body">
              <div class="smith-conversation-body-parts">
                <div class="smith-conversation-parts-wrapper">
                  <div class="smith-conversation-parts">
                    <div
                      class="smith-conversation-part smith-conversation-part-admin"
                    >
                      <div
                        class="smith-comment-container smith-comment-container-admin"
                      >
                        <div class="smith-comment-container-admin-avatar">
                          <div class="smith-avatar">
                            <h3>M</h3>
                          </div>
                        </div>
                        <div class="smith-comment">
                          <div class="smith-blocks">
                            <div class="smith-block smith-block-paragraph">
                              Hi there! 👋 Let me know if you have any
                              questions!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="smith-chat-bar">
        <div class="smith-chat-bar-message">
          <textarea placeholder="Type your message" rows="1"></textarea>
          <button type="button" class="btn send-btn">Send</button>
        </div>
      </div>
    </div>

    <div id="launch" class="smith-launcher-frame">
      <div class="smith-launcher">
        <img src="img/chat-icon.png" alt="chat icon" />
      </div>
    </div>
      
    </>
  )
}

export default Cart