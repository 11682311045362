import React from "react";

function Services() {
  return (
    <div>
      <div class="hero-container-fluid">
        <div class="hero-row">
          <div class="hero-md-12">
            <h3 class="banntext"> Services </h3>
          </div>
        </div>
      </div>

      <div class="hero-servicesban">
        <div class="hero_home_section paddinghome">
          <div class="hero-container-fluid">
            <div class="hero-row">
              <div class="hero-md-7">
                <div class="hero_home_left_content">
                  <div class="hero-row">
                    <div class="hero-md-6">
                      <h4>
                        <a href="https://sxk2111.uta.cloud/MM/">Blogs</a>
                      </h4>
                      <p>
                        Create and share your amazing content with all your
                        mates on MavMarket.
                      </p>
                    </div>
                    <div class="hero-md-6">
                      <h4>
                        <a href="/products">Buy/Sell</a>
                      </h4>
                      <p>Buy or sell amazing products at the price you wish.</p>
                    </div>
                  </div>
                  <div class="hero-row">
                    <div class="hero-md-6">
                      <h4>
                        <a href="/clubs">Clubs</a>
                      </h4>
                      <p>
                        Create and join clubs of your choice. Be the change you
                        want to see!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-md-5">
                <div class="hero_home_right_content">
                  <img src="img/CLUBS-1.png" alt="Services" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                {" "}
                                Hi there! 👋 Let me know if you have any
                                questions!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default Services;
