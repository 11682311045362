import React from "react";
import { Link } from "react-router-dom";

function clubs() {
  return (
    <div>
      <div class="hero-container-fluid">
        <div class="hero-row">
          <div class="hero-md-12">
            <h3 class="banntext">Club Activity</h3>
          </div>
        </div>
      </div>

      <div class="postban">
        <div class="hero_product-view events_section">
          <div class="hero-container-fluid">
            <div class="hero-row">
              <div class="hero-lg-12">
                <div class="hero-sidebar-widget category">
                  <nav class="navbar bg-light">
                    <ul class="navbar-nav sidebarnav">
                      <li class="nav-item">
                        <a class="nav-link" href="#">
                          Art work Exibition
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">
                          Sports
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">
                          Music
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">
                          Extra Activities
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">
                          Cultural Activities
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="hero-lg-12">
                <div class="hero-row">
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club1.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Art Work Exibition</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">
                              Join<Link to="/addclub">Join</Link>
                            </button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club-2.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Sport Activities</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">
                              Join<Link to="/addclub">Join</Link>
                            </button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club3.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Music Activities</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">
                              Join<Link to="/addclub">Join</Link>
                            </button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club-4.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Extra Activities</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">
                              Join<Link to="/addclub">Join</Link>
                            </button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club5.png" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Cultural Activities</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">
                              Join<Link to="/addclub">Join</Link>
                            </button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-footer-bottom">
        <div class="innerhero-footer_bottom">
          <div class="hero-container-fluid">
            <div class="hero-row">
              <div class="hero-md-12 hero-copy-right">
                <p>
                  Copyright &copy; 2022 <a href="#">Student Ecom</a>, All Rights
                  Reserved
                </p>
                <p>
                  Distributed By <a href="#">Student Ecom</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                Hi there! 👋 Let me know if you have any
                                questions!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default clubs;
