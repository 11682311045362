import React from "react";
import { Link } from "react-router-dom";

function ModeratePosts() {
  return (
    <div>
      <div class="admin-view events_section posts_section">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-lg-12 repomod">
              <div class="hero-row">
                <div class="hero-lg-12">
                  <div class="admin-title">
                    <h3>School Admin</h3>
                  </div>
                  <div class="hero-lg-12">
                    <div class="hero-sidebar-widget category">
                      <nav class="navbar bg-light">
                        <ul class="navbar-nav sidebarnav">
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/managestudent">
                              Manage Student
                            </Link>
                          </li>
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/manageowner">
                              Manage Business Owner
                            </Link>
                          </li>
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/moderateposts">
                              Moderate Posts/Clubs
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-row product_col">
                <div class="reports_title rrr_dv">
                  <h3>Moderate Posts</h3>
                </div>
                <div class="hero-row">
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightauto" href="#">
                          <img src="img/studentpost.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <h3>
                          <a href="#">Coolest Work-Study Jobs on Campus</a>
                        </h3>
                      </div>
                      <div class="hero_view_post text-center">
                        <ul>
                          <li>
                            <div class="post_date">25 Sept, 2022</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightauto" href="#">
                          <img src="img/studentpost2.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <h3>
                          <a href="#">Best Study Spots on Campus</a>
                        </h3>
                      </div>
                      <div class="hero_view_post text-center">
                        <ul>
                          <li>
                            <div class="post_date">25 Sept, 2022</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightauto" href="#">
                          <img src="img/studentpost3.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <h3>
                          <a href="#">Experience of Studying Abroad</a>
                        </h3>
                      </div>
                      <div class="hero_view_post text-center">
                        <ul>
                          <li>
                            <div class="post_date">25 Sept, 2022</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hero-row">
            <div class="hero-lg-12">
              <div class="hero-row product_col">
                <div class="reports_title rrr_dv">
                  <h3>Moderate Clubs</h3>
                </div>

                <div class="hero-row">
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club1.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Art Work Exibition</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">Join</button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club-2.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Sport Activities</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">Join</button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-4">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a class="heightclub" href="#">
                          <img src="img/club3.jpg" alt="club Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Music Activities</a>
                      </div>
                      <div class="hero_join_event">
                        <ul>
                          <li>
                            <button class="join_club">Join</button>
                          </li>
                          <li>
                            <button class="leave_club">Leave</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="marginbott"></div>

      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                Hi there! 👋 Let me know if you have any
                                questions about Keypad!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default ModeratePosts;
