import React from "react";

function Home() {
  return (
    <>
      <div class="hero_home_section paddinghome" >
        <div class="hero-container-fluid" >
          <div class="hero-row" >
            <div class="hero-md-6"style={{marginTop:'4rem'}}>
              <div class="hero_home_left_content">
                <h2>Everything you need is now here at Maverick Market. .</h2>
                <h4>
                  Blogs, Clubs, Business, Buy/Sell and much more to come at
                  Maverick Market.
                </h4>
                <p>
                  Look out for this space for more interesting services. We are
                  committed to help you meet your needs at one destination.
                </p>
              </div>
            </div>
            <div class="hero-md-6" style={{marginTop:'8rem'}}>
              <div class="hero_home_right_content">
                <img src="img/index_img1.jpg" alt="Personal-History" />
              </div>
            </div>
            
          </div>
        </div>

        <div id="smith-container">
          <div class="smith-chat-frame">
            <div id="smith-chat-container">
              <div class="smith-chat">
                <div class="smith-chat-header">
                  <div class="smith-header-profile">
                    <div class="smith-header-profile-name">Maverick Market</div>
                  </div>
                </div>
                <div class="smith-chat-body">
                  <div class="smith-conversation-body-parts">
                    <div class="smith-conversation-parts-wrapper">
                      <div class="smith-conversation-parts">
                        <div class="smith-conversation-part smith-conversation-part-admin">
                          <div class="smith-comment-container smith-comment-container-admin">
                            <div class="smith-comment-container-admin-avatar">
                              <div class="smith-avatar">
                                <h3>M</h3>
                              </div>
                            </div>
                            <div class="smith-comment">
                              <div class="smith-blocks">
                                <div class="smith-block smith-block-paragraph">
                                  {" "}
                                  Hi there! 👋 Let me know if you have any
                                  questions!
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="smith-chat-bar">
            <div class="smith-chat-bar-message">
              <textarea placeholder="Type your message" rows="1"></textarea>
              <button type="button" class="btn send-btn">
                Send
              </button>
            </div>
          </div>
        </div>

        <div id="launch" class="smith-launcher-frame">
          <div class="smith-launcher">
            <img src="img/chat-icon.png" alt="chat icon" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
