import React from "react";
import { Link } from "react-router-dom";

function ManageOwner() {
  return (
    <div>
      <div class="Admin-report">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-lg-12">
              <div class="hero-row">
                <div class="hero-lg-12">
                  <div class="admin-title">
                    <h3>School Admin</h3>
                  </div>
                  <div class="hero-lg-12">
                    <div class="hero-sidebar-widget category">
                      <nav class="navbar bg-light">
                      <ul class="navbar-nav sidebarnav">
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/managestudent">
                              Manage Student
                            </Link>
                          </li>
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/manageowner">
                              Manage Business Owner
                            </Link>
                          </li>
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/moderateposts">
                              Moderate Posts/Clubs
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-row">
                <div class="reports_title">
                  <h3>Manage Business Owner</h3>
                </div>
                <div class="hero-lg-12">
                  <div class="admin-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Students</th>
                          <th>user id</th>
                          <th>Email id</th>
                          <th>school</th>
                          <th>address</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Praveen</td>
                          <td>1</td>
                          <td>pr@example.com</td>
                          <td>BG</td>
                          <td>X</td>
                        </tr>
                        <tr>
                          <td>Likhit</td>
                          <td>2</td>
                          <td>li@example.com</td>
                          <td>BG</td>
                          <td>X</td>
                        </tr>
                        <tr>
                          <td>Srijeeth</td>
                          <td>3</td>
                          <td>sr@example.com</td>
                          <td>BG</td>
                          <td>X</td>
                        </tr>
                        <tr>
                          <td>Mark</td>
                          <td>4</td>
                          <td>mk@example.com</td>
                          <td>BG</td>
                          <td>X</td>
                        </tr>
                        <tr>
                          <td>Jeff</td>
                          <td>5</td>
                          <td>jf@example.com</td>
                          <td>BG</td>
                          <td>X</td>
                        </tr>
                        <tr>
                          <td>Bill</td>
                          <td>6</td>
                          <td>bi@example.com</td>
                          <td>BG</td>
                          <td>X</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="table-btn">
                      <ul>
                        <li>
                          <a href="#">Edit</a>
                        </li>
                        <li>
                          <a href="#">Save</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                Hi there! 👋 Let me know if you have any
                                questions about Keypad!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default ManageOwner;
