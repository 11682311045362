import React from "react";
import { Link } from "react-router-dom";

function Checkout() {
  return (
    <div class="checkoutban">
      <div class="hero_home_section">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-md-12">
              <div class="hero_home_left_content text-center">
                <h2>Thank you for your order</h2>
                <h4>
                  Your order number is <b>112234</b>
                </h4>
                <Link
                  class="addcartbtn"
                  to="/products"
                  style={{ textDecoration: "none" }}
                >
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
