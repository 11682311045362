import React from "react";
import { Link } from "react-router-dom";

function BusinessHome() {
  return (
    <div>
      <div class="postban">
        <div class="hero-product-view">
          <div class="hero-container-fluid">
            <div class="hero-row">
              <div class="hero-lg-12 buttonmar">
                <div class="hero-sidebar-widget category">
                  <nav class="navbar bg-light">
                    <ul class="navbar-nav sidebarnav">
                      <li class="nav-item btnnv">
                        <Link class="nav-link clr" to="/addproduct_my">
                          Add Product
                        </Link>
                      </li>
                      <li class="nav-item btnnv">
                        <Link class="nav-link clr" to="/addpromotion">
                          Add Promotion
                        </Link>
                      </li>
                      <li class="nav-item btnnv">
                        <Link class="nav-link clr" to="/chat">
                          Chat History
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="hero-lg-12">
                <div class="hero-row">
                  <div class="hero-lg-12"></div>
                </div>
                <div class="hero-row">
                  <div class="hero-md-3">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a href="cart.html">
                          <img src="img/bookstand.jpg" alt="Product Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Product Name</a>
                        <h3>
                          <span>$</span>99.00
                        </h3>
                        <Link class="addcartbtn" to="/cart">
                          Add to cart
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-3">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a href="cart.html">
                          <img src="img/bookstand2.jpg" alt="Product Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Product Name</a>
                        <h3>
                          <span>$</span>99
                        </h3>
                        <Link class="addcartbtn" to="/cart">
                          Add to cart
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-3">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a href="cart.html">
                          <img src="img/color.jpg" alt="Product Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Product Name</a>
                        <h3>
                          <span>$</span>99
                        </h3>
                        <Link class="addcartbtn" to="/cart">
                          Add to cart
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="hero-md-3">
                    <div class="hero-product-item">
                      <div class="hero-product-image">
                        <a href="cart.html">
                          <img src="img/bookstand5.jpg" alt="Product Image" />
                        </a>
                      </div>
                      <div class="hero-product-price">
                        <a href="#">Product Name</a>
                        <h3>
                          <span>$</span>99
                        </h3>
                        <Link class="addcartbtn" to="/cart">
                          Add to cart
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="smith-container">
          <div class="smith-chat-frame">
            <div id="smith-chat-container">
              <div class="smith-chat">
                <div class="smith-chat-header">
                  <div class="smith-header-profile">
                    <div class="smith-header-profile-name">Maverick Market</div>
                  </div>
                </div>
                <div class="smith-chat-body">
                  <div class="smith-conversation-body-parts">
                    <div class="smith-conversation-parts-wrapper">
                      <div class="smith-conversation-parts">
                        <div class="smith-conversation-part smith-conversation-part-admin">
                          <div class="smith-comment-container smith-comment-container-admin">
                            <div class="smith-comment-container-admin-avatar">
                              <div class="smith-avatar">
                                <h3>M</h3>
                              </div>
                            </div>
                            <div class="smith-comment">
                              <div class="smith-blocks">
                                <div class="smith-block smith-block-paragraph">
                                  {" "}
                                  Hi there! 👋 Let me know if you have any
                                  questions about Keypad!
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="smith-chat-bar">
            <div class="smith-chat-bar-message">
              <textarea placeholder="Type your message" rows="1"></textarea>
              <button type="button" class="btn send-btn">
                Send
              </button>
            </div>
          </div>
        </div>

        <div id="launch" class="smith-launcher-frame">
          <div class="smith-launcher">
            <img src="img/chat-icon.png" alt="chat icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessHome;
