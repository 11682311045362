import React from "react";

function AddProduct() {
  return (
    <div>
      <div class="admin-view admin-sec">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-lg-12">
              <div class="add-product-form">
                <div class="add-prod">
                  <h3>Add Products</h3>
                </div>
                <div class="product-content">
                  <div class="image-block"></div>
                  <div class="product-form">
                    <ul>
                      <li>
                        <h3>Product Name</h3>
                        <input type="text" placeholder="Title" />
                      </li>
                      <li>
                        <h3>Product Type</h3>
                        <input type="text" placeholder="Type" />
                      </li>
                      <li>
                        <h3>Product Image</h3>
                        <input type="file" />
                      </li>
                      <li>
                        <h3>Product Price</h3>
                        <input type="text" placeholder="Price" />
                      </li>
                      <li>
                        <div class="btn_adnn">
                          <button class="add-btn-prod">Add</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                {" "}
                                Hi there! 👋 Let me know if you have any
                                questions about Keypad!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
