import React from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const onLogout = () => {
    localStorage.removeItem("user_type");
    window.location.href = "/";
  };

  const route_links = {
    normal: [
      {
        label: "Home",
        path: "/",
      },
      {
        label: "About",
        path: "/about",
      },
      {
        label: "services",
        path: "/services",
      },
      {
        label: "Contact",
        path: "/contact",
      },

      {
        label: "Blog",
        outer_link: "https://sxk2111.uta.cloud/MM/",
      },
      {
        label: "Login",
        path: "/auth",
      },
    ],
    student: [
      {
        label: "Home",
        path: "/studenthome",
      },
      {
        label: "About",
        path: "/about",
      },
      {
        label: "services",
        path: "/services",
      },
      {
        label: "Profile",
        path: "/profile",
      },
      {
        label: "Products",
        path: "/products",
      },{
        label: "Blogs",
        path: "https://sxk2111.uta.cloud/MM/",
      },
      {
        label: "Clubs",
        path: "/clubs",
      },
      {
        label: "Contact",
        path: "/contact",
      },
     
      {
        label: "Log Out",
        logout: onLogout,
      },
    ],
    super_user: [
      {
        label: "Home",
        path: "/homesuper",
      },
      {
        label: "About",
        path: "/about",
      },
      {
        label: "services",
        path: "/services",
      },

      {
        label: "Blog",
        outer_link: "#",
      },
      {
        label: "Log Out",
        logout: onLogout,
      },
    ],

    school_admin: [
      {
        label: "Home",
        path: "/schoolhome",
      },

      {
        label: "About",
        path: "/about",
      },
      {
        label: "services",
        path: "/services",
      },
      {
        label: "Blog",
        outer_link: "#",
      },
      {
        label: "Log Out",
        logout: onLogout,
      },
    ],
    business_owner: [
      {
        label: "Home",
        path: "/businesshome",
      },

      {
        label: "About",
        path: "/about",
      },
      {
        label: "services",
        path: "/services",
      },
      {
        label: "Log Out",
        logout: onLogout,
      },
    ],
  };

  const user_type = localStorage.getItem("user_type") || "normal";
  const routes = route_links[user_type];
  const { pathname } = useLocation();

  console.log(pathname);
  return (
    <div class="hero_header-bar">
      <div class="hero-container-fluid">
        <div class="hero-row align-items-center">
          <div class="hero-md-2">
            <div class="logo">
              <a href="#">
                <img src="img/logod.png" alt="Logo" />
              </a>
            </div>
          </div>

          <div class="hero-md-8">
            <div class="nav">
              <nav class="navbar">
                <div class="navbar-nav" id="myTopnav">
                  <Link
                    to="javascript:void(0);"
                    class="icon togglemenu"
                    onclick="togglefun()"
                  >
                    <i class="fa fa-bars"></i>
                  </Link>
                  {routes?.map((link, key) => (
                    <>
                      {link?.outer_link ? (
                        <a href={link?.outer_link} className="nav-item nav-link" target="__blank">
                          {link.label}
                        </a>
                      ) : link?.logout ? (
                        <a href="#" onClick={link.logout} className="nav-item nav-link">
                          {link.label}
                        </a>
                      ) : (
                        <Link
                          to={link.path}
                          class={
                            link.path === pathname
                              ? "nav-item nav-link active"
                              : "nav-item nav-link"
                          }
                        >
                          {link.label}
                        </Link>
                      )}
                    </>
                  ))}
                  
                </div>
              </nav>
            </div>
          </div>

          <div class="hero-md-2">
            <div class="hero_user">
              <div class="hero_cart_section">
                <a href="#" class="cart">
                  <i class="fa fa-shopping-cart"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
