import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddProduct from "./container/business/AddProduct";
import AddPromotion from "./container/business/AddPromotion";
import BusinessHome from "./container/business/Home";
import StudentChat from "./container/business/StudentChat";
import Navbar from "./container/Navbar";
import About from "./container/normal/About";
import Contact from "./container/normal/Contact";
import Home from "./container/normal/Home";
import Services from "./container/normal/Services";
import SIgnup from "./container/normal/SIgnup";
import AddPost from "./container/student/AddPost";
import AddProductStudent from "./container/student/AddProduct";
import Cart from "./container/student/Cart";
import Checkout from "./container/student/Checkout";
import StudentHome from "./container/student/Home";
import Posts from "./container/student/Posts";
import Products from "./container/student/Products";
import Profile from "./container/student/Profile";
import Clubs from "./container/student/Clubs";
import AddClub from "./container/student/AddClub";
import SchoolHome from "./container/school/Home";
import ManageOwner from "./container/school/ManageOwner";
import ManageStudents from "./container/school/ManageStudents";
import ModeratePosts from "./container/school/ModeratePosts";
import SuperHome from "./container/super/Home";
import ManageSchool from "./container/super/ManageSchool";
import ManageUser from "./container/super/ManageUser";
import ViewQuery from "./container/super/ViewQuery";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/auth" element={<SIgnup />} />

          <Route path="/businesshome" element={<BusinessHome />} />
          <Route path="/addproduct_my" element={<AddProduct />} />
          <Route path="/addpromotion" element={<AddPromotion />} />
          <Route path="/chat" element={<StudentChat />} />

          <Route path="/studenthome" element={<StudentHome />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/products" element={<Products />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/addproduct" element={<AddProductStudent />} />
          <Route path="/addpost" element={<AddPost />} />
          <Route path="/clubs" element={<Clubs />} />
          <Route path="/addclub" element={<AddClub />} />

          <Route path="/schoolhome" element={<SchoolHome />} />
          <Route path="/manageowner" element={<ManageOwner />} />
          <Route path="/managestudent" element={<ManageStudents />} />
          <Route path="/moderateposts" element={<ModeratePosts />} />

          <Route path="/homesuper" element={<SuperHome />} />
          <Route path="/manageschool" element={<ManageSchool />} />
          <Route path="/manageuser" element={<ManageUser />} />
          <Route path="/viewquery" element={<ViewQuery />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
