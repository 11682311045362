import React from "react";

function About() {
  return (
    <div>
      <div class="hero-container-fluid">
        <div class="hero-row">
          <div class="hero-md-12">
            <h3 class="banntext"> About Us </h3>
          </div>
        </div>
      </div>

      <div class="hero_home_section">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-md-4">
              <div class="hero_home_right_content">
                <img
                  src="img/aboutstor.png"
                  class="aboutcircl"
                  alt="Personal-History"
                />
              </div>
            </div>
            <div class="hero-md-8">
              <div class="hero_home_left_content">
                <h2 class="pddtopbtm">Some Words About Us</h2>
                <h4>
                  We are a one stop solution for all your needs ranging from
                  blogging to promoting your business.
                </h4>
                <p>
                  We are committed to provide the best possible services for all
                  the needs of our maverick students. Hope you have a great time
                  at MaverickMarket.
                </p>
                <p>
                  All your business needs is promotion to reach new landscapes.
                  We are committed to provide relentless ad service at an
                  affordable price. Please contact us for any business queries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                {" "}
                                Hi there! 👋 Let me know if you have any
                                questions about Keypad!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default About;
