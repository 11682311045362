import React, { useState } from "react";

function SIgnup() {
  const credentials = [
    {
      id: 1,
      username: "student",
      password: "123",
      type: "student",
      label: "studenthome",
    },
    {
      id: 2,
      username: "schooladmin",
      password: "123",
      type: "school_admin",
      label: "schoolhome",
    },
    {
      id: 3,
      username: "businessowner",
      password: "123",
      type: "business_owner",
      label: "businesshome",
    },
    {
      id: 4,
      username: "superuser",
      password: "123",
      type: "super_user",
      label: "homesuper",
    },
  ];

  const [details, setDetails] = useState({ username: "", pass: "" });

  const onChange = ({ target: { value, name } }) => {
    const latest = { ...details };
    latest[name] = value;
    setDetails(latest);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = credentials.find(
      (item) =>
        item.username == details.username && item.password == details.pass
    );

    if (!user) return alert("Invalid username/password !");

    localStorage.setItem("user_type", user?.type);
    window.location.href = "/" + user?.label;
  };
  return (
    <div>
      <div class="hero_login_bg paddinghome">
        <div class="hero_login">
          <div class="hero-container-fluid">
            <div class="hero-row">
              <div class="hero-lg-7">
                <div class="hero-register-form">
                  <h3 class="text-center">Register Here</h3>
                  <form>
                    <div class="hero-row">
                      <div class="hero-md-6">
                        <label>Full Name</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Full Name"
                        />
                      </div>
                      <div class="hero-md-6">
                        <label>E-mail</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="E-mail"
                        />
                      </div>
                    </div>
                    <div class="hero-row">
                      <div class="hero-md-6">
                        <label>Mobile No</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Mobile No"
                        />
                      </div>
                      <div class="hero-md-6">
                        <label>Password</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div class="hero-row">
                      <div class="hero_fg_group">
                        <a class="btn" id="submit">
                          Submit
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="hero-lg-5">
                <form id="form_id" onSubmit={onSubmit}>
                  <div class="hero_login-form">
                    <h3>Login</h3>
                    <div class="hero-row">
                      <div class="hero-md-12">
                        <div class="hero_fg_group">
                          <label>E-mail / Username</label>
                          <input
                            class="form-control"
                            type="text"
                            id="text1"
                            placeholder="E-mail / Username"
                            name="username"
                            value={details.username}
                            onChange={onChange}
                          />
                        </div>
                        <div class="hero_fg_group">
                          <label>Password</label>
                          <input
                            class="form-control"
                            type="password"
                            id="text2"
                            placeholder="Password"
                            name="pass"
                            value={details.pass}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div class="hero-md-12">
                        <div class="hero_fg_group">
                          <button class="btn">Submit</button>
                        </div>
                        <div class="hero-md-12">
                          <div class="hero_fg_group">
                            <button class="btn left">Forgot password</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SIgnup;
