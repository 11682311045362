import React from "react";
import { Link } from "react-router-dom";

function ViewQuery() {
  return (
    <div>
      <div class="Admin-report">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-lg-12">
              <div class="hero-row">
                <div class="hero-lg-12">
                  <div class="admin-title">
                    <h3>Super Admin</h3>
                  </div>
                  <div class="hero-lg-12">
                    <div class="hero-sidebar-widget category">
                      <nav class="navbar bg-light">
                      <ul class="navbar-nav sidebarnav">
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/manageuser">
                              Manage User
                            </Link>
                          </li>
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/manageschool">
                              Manage School
                            </Link>
                          </li>
                          <li class="nav-item btnnv">
                            <Link class="nav-link clr" to="/viewquery">
                              View Queries
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-row">
                <div class="reports_title">
                  <h3>View Queries</h3>
                </div>
                <div class="hero-lg-12">
                  <div class="view-queries">
                    <ul>
                      <li>
                        <div class="q-box">
                          <div class="qmessage">
                            <h3>Jack</h3>
                            <p>Facing problem with purchase of item</p>
                            <p></p>
                          </div>

                          <a href="#">Reply</a>
                        </div>
                      </li>

                      <li>
                        <div class="q-box">
                          <div class="qmessage">
                            <h3>Raju</h3>
                            <p>Can help me by adding me to club</p>
                          </div>
                          <a href="#">Reply</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="smith-container">
        <div class="smith-chat-frame">
          <div id="smith-chat-container">
            <div class="smith-chat">
              <div class="smith-chat-header">
                <div class="smith-header-profile">
                  <div class="smith-header-profile-name">Maverick Market</div>
                </div>
              </div>
              <div class="smith-chat-body">
                <div class="smith-conversation-body-parts">
                  <div class="smith-conversation-parts-wrapper">
                    <div class="smith-conversation-parts">
                      <div class="smith-conversation-part smith-conversation-part-admin">
                        <div class="smith-comment-container smith-comment-container-admin">
                          <div class="smith-comment-container-admin-avatar">
                            <div class="smith-avatar">
                              <h3>M</h3>
                            </div>
                          </div>
                          <div class="smith-comment">
                            <div class="smith-blocks">
                              <div class="smith-block smith-block-paragraph">
                                Hi there! 👋 Let me know if you have any
                                questions about Keypad!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="smith-chat-bar">
          <div class="smith-chat-bar-message">
            <textarea placeholder="Type your message" rows="1"></textarea>
            <button type="button" class="btn send-btn">
              Send
            </button>
          </div>
        </div>
      </div>

      <div id="launch" class="smith-launcher-frame">
        <div class="smith-launcher">
          <img src="img/chat-icon.png" alt="chat icon" />
        </div>
      </div>
    </div>
  );
}

export default ViewQuery;
