import React from 'react'

function Profile() {
  return (
    <div>
        <div class="hero-container-fluid">
      <div class="hero-row">
        <div class="hero-md-12">
          <h3 class="banntext">My Profile</h3>
        </div>
      </div>
    </div>

    <div class="hero-product-view profile_section">
      <div class="hero-container-fluid">
        <div class="hero-row">
          <div class="hero-lg-3 hero-md-12">
            <div class="hero-sidebar-widget profile_block">
              <div class="hero_profile_pic">
                <img src="img/profile.png" alt="profile" />
              </div>
              <div class="hero_profile_details">
                <h6>ABC XYZ</h6>
                <div class="hero_pr_details">
                  <ul>
                    <li>
                      <i class="fa fa-envelope"></i> <span>PC@gmail.com</span>
                    </li>
                    <li>
                      <i class="fa fa-phone"></i> <span>8888 880000</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="hero-lg-9">
            <div class="hero_product">
              <div class="hero_product_section">
                <div class="hero_title">
                  <h3>Products</h3>
                  <div class="hero_add_product">
                    <a href="#popup1">Add Product</a>
                    <div id="popup1" class="overlay">
                      <div class="popup">
                        <h2>Add Product</h2>
                        <a class="close" href="#">&times;</a>
                        <div class="content">
                          <form>
                            <div class="hero-row">
                              <div class="hero-md-12">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Product Name"
                                />
                              </div>
                              <div class="hero-md-12">
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/png, image/jpeg"
                                />
                              </div>
                              <div class="hero-md-12">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Price"
                                />
                              </div>
                              <div class="hero-md-12">
                                <a class="popbtn" id="submit">Submit</a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="hero-row">
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="cart.html">
                        <img src="img/bookstand.jpg" alt="Product Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <a href="#">Product Name</a>
                      <h3><span>$</span>99.00</h3>
                      <a class="addcartbtn" href="cart.html">Add to cart</a>
                    </div>
                  </div>
                </div>
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="cart.html">
                        <img src="img/bookstand2.jpg" alt="Product Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <a href="#">Product Name</a>
                      <h3><span>$</span>99</h3>
                      <a class="addcartbtn" href="cart.html">Add to cart</a>
                    </div>
                  </div>
                </div>
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="cart.html">
                        <img src="img/color.jpg" alt="Product Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <a href="#">Product Name</a>
                      <h3><span>$</span>99</h3>
                      <a class="addcartbtn" href="cart.html">Add to cart</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hero_club">
              <div class="hero_product_section">
                <div class="hero_title">
                  <h3>Clubs</h3>
                  <div class="hero_add_product">
                    <a href="#popup2">Add Club</a>
                    <div id="popup2" class="overlay">
                      <div class="popup">
                        <h2>Add Club</h2>
                        <a class="close" href="#">&times;</a>
                        <div class="content">
                          <form>
                            <div class="hero-row">
                              <div class="hero-md-12">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Club Name"
                                />
                              </div>
                              <div class="hero-md-12">
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/png, image/jpeg"
                                />
                              </div>
                              <div class="hero-md-12">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Description"
                                />
                              </div>
                              <div class="hero-md-12">
                                <a class="popbtn" id="submit">Submit</a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-row">
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="#">
                        <img src="img/club1.jpg" alt="club Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <a href="#">Art Work Exibition</a>
                    </div>
                    <div class="hero_join_event">
                      <ul>
                        <li><button class="join_club">Join</button></li>
                        <li><button class="leave_club">Leave</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="#">
                        <img src="img/club-2.jpg" alt="club Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <a href="#">Sport Activities</a>
                    </div>
                    <div class="hero_join_event">
                      <ul>
                        <li><button class="join_club">Join</button></li>
                        <li><button class="leave_club">Leave</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="#">
                        <img src="img/club3.jpg" alt="club Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <a href="#">Music Activities</a>
                    </div>
                    <div class="hero_join_event">
                      <ul>
                        <li><button class="join_club">Join</button></li>
                        <li><button class="leave_club">Leave</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hero_posts">
              <div class="hero_product_section">
                <div class="hero_title">
                  <h3>Posts</h3>
                  <div class="hero_add_product">
                    <a href="#popup3">Add Post</a>
                    <div id="popup3" class="overlay">
                      <div class="popup">
                        <h2>Add Post</h2>
                        <a class="close" href="#">&times;</a>
                        <div class="content">
                          <form>
                            <div class="hero-row">
                              <div class="hero-md-12">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Post Title"
                                />
                              </div>
                              <div class="hero-md-12">
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/png, image/jpeg"
                                />
                              </div>
                              <div class="hero-md-12">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Description"
                                />
                              </div>
                              <div class="hero-md-12">
                                <a class="popbtn" id="submit">Submit</a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-row">
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="#">
                        <img src="img/studentpost.jpg" alt="club Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <h3><a href="#">Coolest Work-Study Jobs</a></h3>

                    </div>
                    <div class="hero_view_post text-center">
                      <ul>
                        <li><div class="post_date">25 Sept, 2022</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="#">
                        <img src="img/studentpost2.jpg" alt="club Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <h3><a href="#">Coolest Work-Study Jobs</a></h3>
    
                    </div>
                    <div class="hero_view_post text-center">
                      <ul>
                        <li><div class="post_date">25 Sept, 2022</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightclub" href="#">
                        <img src="img/studentpost3.jpg" alt="club Image" />
                      </a>
                    </div>
                    <div class="hero-product-price">
                      <h3><a href="#">Coolest Work-Study Jobs</a></h3>
                      
                    </div>
                    <div class="hero_view_post text-center">
                      <ul>
                        <li><div class="post_date">25 Sept, 2022</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="smith-container">
      <div class="smith-chat-frame">
        <div id="smith-chat-container">
          <div class="smith-chat">
            <div class="smith-chat-header">
              <div class="smith-header-profile">
                <div class="smith-header-profile-name">Maverick Market</div>
              </div>
            </div>
            <div class="smith-chat-body">
              <div class="smith-conversation-body-parts">
                <div class="smith-conversation-parts-wrapper">
                  <div class="smith-conversation-parts">
                    <div
                      class="smith-conversation-part smith-conversation-part-admin"
                    >
                      <div
                        class="smith-comment-container smith-comment-container-admin"
                      >
                        <div class="smith-comment-container-admin-avatar">
                          <div class="smith-avatar">
                            <h3>M</h3>
                          </div>
                        </div>
                        <div class="smith-comment">
                          <div class="smith-blocks">
                            <div class="smith-block smith-block-paragraph">
                              Hi there! 👋 Let me know if you have any
                              questions!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="smith-chat-bar">
        <div class="smith-chat-bar-message">
          <textarea placeholder="Type your message" rows="1"></textarea>
          <button type="button" class="btn send-btn">Send</button>
        </div>
      </div>
    </div>

    <div id="launch" class="smith-launcher-frame">
      <div class="smith-launcher">
        <img src="img/chat-icon.png" alt="chat icon" />
      </div>
    </div>
    </div>
  )
}

export default Profile