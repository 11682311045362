import React from 'react'

function AddPost() {
  return (
    <div>
        <div class="hero-container-fluid">
      <div class="hero-row">
        <div class="hero-md-12">
          <h3 class="banntext">Add Blog</h3>
        </div>
      </div>
    </div>

    <div class="postban">
      <div class="hero_product-view posts_section">
        <div class="hero-container-fluid">
          <div class="hero-row">
            <div class="hero-lg-12">
              <div class="hero-row">
                <div class="hero-md-4">
                  <div class="hero-product-item">
                    <div class="hero-product-image">
                      <a class="heightauto" href="#">
                        <img src="img/studentpost.jpg" alt="club Image" />
                      </a>
                    </div>
                  </div>

                  <div class="hero-container-fluid">
                    <div class="hero-row">
                      <div class="hero-md-12">
                        <h3 class="addpost">Add Blog</h3>
                      </div>
                      <div class="hero-md-12">
                        <div class="Add-post-form">
                          <form>
                            <div class="form-group">
                              <h3>Blog Title</h3>
                              <input type="text" class="form-control" />
                            </div>
                            <h3>Blog Description</h3>
                            <div class="form-group">
                              <input type="text" class="form-control" />
                            </div>
                            <h3>Product Image</h3>
                            <div class="form-group">
                              <input type="file" class="form-control" />
                            </div>

                            <div>
                              <button class="btn" type="submit">Post</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="smith-container">
              <div class="smith-chat-frame">
                <div id="smith-chat-container">
                  <div class="smith-chat">
                    <div class="smith-chat-header">
                      <div class="smith-header-profile">
                        <div class="smith-header-profile-name">
                          Maverick Market
                        </div>
                      </div>
                    </div>
                    <div class="smith-chat-body">
                      <div class="smith-conversation-body-parts">
                        <div class="smith-conversation-parts-wrapper">
                          <div class="smith-conversation-parts">
                            <div
                              class="smith-conversation-part smith-conversation-part-admin"
                            >
                              <div
                                class="smith-comment-container smith-comment-container-admin"
                              >
                                <div
                                  class="smith-comment-container-admin-avatar"
                                >
                                  <div class="smith-avatar">
                                    <h3>M</h3>
                                  </div>
                                </div>
                                <div class="smith-comment">
                                  <div class="smith-blocks">
                                    <div
                                      class="smith-block smith-block-paragraph"
                                    >
                                      Hi there! 👋 Let me know if you have any
                                      questions!
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="smith-chat-bar">
                <div class="smith-chat-bar-message">
                  <textarea placeholder="Type your message" rows="1"></textarea>
                  <button type="button" class="btn send-btn">Send</button>
                </div>
              </div>
            </div>

            <div id="launch" class="smith-launcher-frame">
              <div class="smith-launcher">
                <img src="img/chat-icon.png" alt="chat icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default AddPost