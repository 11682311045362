import React from 'react'

function StudentHome() {
  return (
    <div>
      <div class="hero-container-fluid">
        <div class="hero-row">
          <div class="hero-md-12">
            <h3 class="banntext">Student Home</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentHome