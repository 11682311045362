import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Chart() {
  const options = {
    responsive: true,
    gridLine: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const labels = [
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Years",
        data: [15, 20, 25, 23, 30, 45, 70, 60, 50, 40, 30],
        backgroundColor: ["#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",
        "#f16335",],
      },
    ],
  };
  return <Bar options={options} data={data} />;
}

export default Chart;
